@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    text-decoration: none;
    list-style: none;
}

img,
svg,
video {
    height: auto;
    max-width: 100%;
    object-fit: cover;
}
body{
  font-family: "Chakra Petch", sans-serif;
}
:root {
    --color: #0ACA37;
    --chakra: "Chakra Petch", sans-serif;
    --pop: "Poppins", sans-serif;
}

.ant-modal-footer{
    display: none;
}
.modal_header{
    margin-top: 25px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}
.modal_header img{
    width: 150px;
}
.modal_form input{
    height: 50px;
    margin-bottom: 15px;
}
.modal_form label{
    font-size: 18px;
    text-transform: capitalize;
}
.modal_form button{
    background-color: #0ACA37;
    font-size: 18px;
    width: 200px;
    height: 40px;
}
.ant-notification{
    z-index: 10000 !important;
}