nav {
    width: 100%;
    padding-top: 20px;
}

nav a.logo img {
    height: 60px;
    object-fit: contain;
}

nav .col-lg-12 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

nav ul {
    display: flex;
    margin: 0px;
    grid-gap: 10px;
    backdrop-filter: blur(15px);
    background: transparent;
    align-items: center;
    padding: 10px 7px;
    border: #575857 solid 1px;
    border-radius: 10px;
}

nav ul li a {
    padding: 5px 7px;
    color: white;
    text-decoration: none;
    font-weight: 700;
    font-size: 12px;
    font-family: var(--pop);
    text-transform: uppercase;
}
nav ul li a:hover,
nav ul li select:hover{
    cursor: pointer;
    background: rgba(255, 255, 255, 0.2 );
    transition: .5s;
}
nav ul li select{
    background: transparent;
    border: none;
    padding: 0px 7px;
    color: white;
    font-family: var(--pop);
    font-weight: 700;
    font-size: 12px;

}
nav ul li select option{
    font-family: var(--pop);
    font-weight: 700;
    font-size: 12px;
    background: black;
}
nav ul li select:focus{
    outline: none;
}

nav button{
    padding: 18px 30px;
    background: white;
    border: none;
    font-size: 14px;
    font-weight: 600;
}

.navburger{
    display: none;
    color: white;
    font-size: 25px;
}
.menu{
    display: flex;
    justify-content: flex-end;
    width: 65%;
    z-index: 999;
}
.navburger_inMenu{
    position: absolute;
    top: 20px;
    font-size: 20px;
    color: white !important;
    background: #1B1917;
    right: 20px;
    cursor: pointer;
}
.navburger:hover{
    color: #0ACA37 !important;
    cursor: pointer;
}
.navburger_inMenu:hover{
    color: #0ACA37 !important;
}
@media screen and (max-width: 1000px){
    nav .logo{
        width: 15%;
    }
    nav a.logo img{
        width: 100%;
        height: 100%;
    }
    .navburger{
        display: block;
    }
    .menu{
        width: 60%;
        position: fixed;
        left: -200%;
        height: 100vh;
        bottom: 0;
    }
    .menu.open{
        left: 0;
        background: #1B1917;
    }
    .menu.open ul{
        display: flex !important;
        flex-direction: column;
        width: 100%;
        margin-top: 30px;
        background: #1B1917;
        justify-content: start;
        align-items: start;
        border: none;
    }
    .menu.open ul li{
        width: 100%;
        margin-bottom: 15px;
    }
    .menu.open ul li a{
        font-weight: 400;
        font-size: 14px
    }
    .menu.open ul .logo{
        margin-bottom: 10px;
        width: 47%;
        height: 50px;
        object-fit: cover;
    }
    .menu.open ul .logo img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

@media screen and (max-width: 750px){
    nav .logo{
        width: 30%;
    }
}