header {
    width: 100%;
    z-index: 1111;
    position: relative;
}

.header_opacity {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    /*backdrop-filter: blur(5px);*/
    z-index: -1;
    clip-path: polygon(0% 0%, 100% 0, 100% 85%, 86% 100%, 0% 100%);
}

.foto-res {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: -11;

}
.background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transition: opacity 1s ease-in-out;
    clip-path: polygon(0% 0%, 100% 0, 100% 85%, 86% 100%, 0% 100%);
}

.fade-in {
    opacity: 1;
    z-index: 1;
}

.fade-out {
    opacity: 0;
    z-index: 0;
}


header h1.top {
    font-size: 30px;
    font-weight: 600;
    font-family: var(--chakra);
    text-transform: uppercase;
    color: white;
    margin-bottom: 0px;
    padding-top: 100px;
}

header h1.top span {
    color: var(--color);
}

header p.bottom {
    padding: 20px 0px 40px 0px;
    color: rgba(255, 255, 255, .8);
    font-family: var(--pop);
    margin-bottom: 0px;
}

header .btns {
    display: flex;
    align-items: center;
    grid-gap: 25px;
    padding: 50px 0px 200px 0px;
}

header .btns button.left {
    background: var(--color);
    padding: 15px 35px;
    font-weight: 600;
    border: none;
}

header .btns a.right {
    background: transparent;
    padding: 15px 35px;
    border: 1px solid white;
    color: white;
    font-weight: 600;
    text-decoration: none;
}

header .btns button.right:hover {
    cursor: pointer;
    transition: .2s;
    background: white;
    color: black;
}

header .btns button.left:hover {
    cursor: pointer;
    background: white;
}

header .box-bottom {
    box-shadow: 2px 10px 10px rgba(0, 0, 0, .1);
    padding: 20px 20px 70px 20px;
    backdrop-filter: blur(15px);
    position: absolute;
    bottom: -30px;
    right: 70px;
}

header .box-bottom iframe {
    width: 300px;
    height: 175px;
    margin-bottom: 20px;
}

header .box-bottom .box {
    display: flex;
    align-items: center;
    grid-gap: 20px;
}

header .box-bottom .box .img {
    display: flex;
}

header .box-bottom .box .img img {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    border: 1.5px solid white;
}

header .box-bottom .box .img p.count {
    padding: 13px 10px;
    background: white;
    border-radius: 50%;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 0px;
}

header .box-bottom .box .img img:nth-child(2),
header .box-bottom .box .img img:nth-child(3),
header .box-bottom .box .img p.count {
    margin-left: -15px;
}

header .box-bottom .box p.right {
    font-size: 16px;
    font-family: var(--chakra);
    color: rgba(255, 255, 255, .8);
    margin-bottom: 0px;
}

header .box-bottom .box-end {
    display: flex;
    align-items: center;
    grid-gap: 20px;
}

header .box-bottom .box-end p.right-bottom {
    margin-bottom: 0px;
    color: rgba(255, 255, 255, .8);
    font-size: 16px;
}

header .box-bottom p.bottom-p span,
header .box-bottom p.right-bottom span {
    color: white;
}

header .box-bottom p.bottom-p {
    margin-bottom: 0px;
    color: rgba(255, 255, 255, .8);
    font-size: 16px;
}

/* From Uiverse.io by PriyanshuGupta28 */
.rating {
    display: inline-block;
}

.rating input {
    display: none;
}

.rating label {
    float: right;
    cursor: pointer;
    color: #ccc;
    transition: color 0.3s;
}

.rating label:before {
    content: '\2605';
    font-size: 30px;
}

.rating input:checked ~ label,
.rating label:hover,
.rating label:hover ~ label {
    color: var(--color);
    transition: color 0.3s;
}

@media screen and (max-width: 1000px) {
    header .box-bottom {
        bottom: -100px;
        right: 0;
    }

    header {
        height: 100vh;
    }
}

@media screen and (max-width: 800px) {
    header h1.top {
        font-size: 1.2rem;
    }

    header .box-bottom {
        /*bottom: 0px;*/
        left: auto;
        top: 68%;
        bottom: auto;
        right: auto;
    }

    header h1.top {
        padding-top: 70px;
    }

    header .btns {
        padding: 0;
    }

    header {
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .foto-res img {
        clip-path: none;
    }
}