.talk_about{
    background-image: url("../../Assisstens/etbs/talk_about.webp");
    background-attachment: fixed;
    background-size: cover;
    width: 100%;
    height: 500px;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.talk_about_opacity{
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: -1;
}
.talk_about_text{
    color: white;
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Chakra Petch", sans-serif;
    text-align: center;
    padding: 0 10px;
}
.talk_about_text h1{
    font-size: 50px;
    font-weight: 600;
    text-align: center;
}
.talk_about_text button{
    width: 190px;
    height: 40px;
    padding: 25px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0ACA37;
    outline: none;
    border: none;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
    color: white;

}
.talk_about_text button:hover{
    background: #1B1917;
    color: #0ACA37;
}

@media screen and (max-width: 800px){
    .talk_about_text h1{
        font-size: 23px;
    }
    .talk_about_text{
        width: 100%;
    }
}