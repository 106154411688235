footer {
    width: 100%;
    background: #1B1917;
    margin-top: 50px;
    padding: 50px 0;

}

footer .container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer_box {
    width: 90%;
}

.footer_box_top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: #666 1px solid;
    padding-bottom: 50px;
}

.footer_box_top_text {
    color: #FFFFFF;
}

.footer_box_top_text h1 {
    text-transform: uppercase;
    font-size: 30px;
}

.footer_box_top_text h1, .footer_box_top_text p {
    font-family: "Chakra Petch", sans-serif;
}

.footer_box_top_input {
    width: 400px;
    position: relative;
}

.footer_box_top_input input {
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
    padding: 22px 15px;
    clip-path: polygon(0% 0%, 100% 0, 100% 80%, 95% 100%, 0% 100%)
}

.footer_box_top_input button {
    position: absolute;
    color: #FFFFFF;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: #0ACA37;
    padding: 15px;
    font-weight: 500;
    text-transform: uppercase;
    outline: none;
    border: none;
    clip-path: polygon(0% 0%, 100% 0, 100% 80%, 86% 100%, 0% 100%);

}

.footer_box_bottom {
    width: 100%;
    display: flex;
    margin-top: 20px;
    gap: 20px;
    align-items: flex-start;
    justify-content: flex-start;
}

.footer_box_bottom_item {
    width: 25%;
}

.footer_box_bottom_item_logo {
    margin-right: 70px;
}

.footer_box_bottom_item_logo img {
    width: 70%;
    margin-bottom: 10px;

}

.footer_box_bottom_item_logo p {
    color: #666666;
    width: 100%;

}

.footer_box_bottom_item_social {
    display: flex;
    gap: 20px;
    font-size: 20px;
    color: white;
}

.footer_box_bottom_item_social a {
    color: #FFFFFF
}

.footer_box_bottom_item h1 {
    text-transform: uppercase;
    font-family: "Chakra Petch", sans-serif;
    font-size: 25px;
    margin-top: 0;
    color: #FFFFFF;
}

.footer_box_bottom_item ul {
    padding: 0;
}

.footer_box_bottom_item ul li {
    margin-bottom: 5px;
}

.footer_box_bottom_item ul li a {
    text-decoration: none;
    text-transform: lowercase;
    color: #b1b1b1;
}
.footer_box_bottom_item ul li:last-child a{
    text-transform: none;
}
.footer_box_bottom_item_contact ul li a {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 15px;
}

.footer_box_bottom_item_contact ul li a i {
    width: 14%;
}

.footer_box_bottom_item_contact ul li:last-child a {
    align-items: flex-start;
}

.footer_box_bottom_item_contact ul li a p {
    margin: 0;
    width: 80%;
    /*font-size: 18px;*/
}

@media screen and (max-width: 1000px) {
    .footer_box_bottom_item:last-child {
        width: 30%;
    }

    .footer_box_bottom_item_contact ul li a p {
        font-size: 14px;
    }

    .footer_box_bottom_item_logo {
        margin-right: 0;

    }

}


@media screen and (max-width: 800px) {
    .footer_box_top_input{
        width: 100%;
    }
    .footer_box_top {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .footer_box_bottom {
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 0;
    }

    .footer_box_bottom_item {
        width: 48%;
    }

    .footer_box_bottom_item_logo {
        width: 100%;
        margin-bottom: 20px;
    }
    .footer_box_bottom_item_logo_text{
        flex-direction: column;
    }
    .footer_box_bottom_item:last-child{
        width: 48%;
    }
}